import React from "react";
import { DataPrivacy } from "@brainfinance/icash-component-library";

import LogoSecurity from "@images/security-placeholder.png";
import LogoPrivacy from "@images/privacy-placeholder.png";

export const DataPrivacySection = () => (
  <DataPrivacy logoPrivacy={LogoPrivacy} logoSecurity={LogoSecurity}>
      Committed to keeping your data <span className="text-interface-300">safe and secure</span>
  </DataPrivacy>
);