import * as React from "react";
import { Layout, LayoutDescription, LayoutHeader, LayoutTitle, CreditScore } from "@brainfinance/icash-component-library";
import { generateTitle } from "@helpers/generateTitle";
import { LayoutProps } from "@types";
//images
import logo from "@images/partners/partner-logo-equifax.png";

export const CreditScoreSection = (props: LayoutProps) => (
  <Layout>
    <LayoutHeader>
      <LayoutTitle>
        {generateTitle({
          title: props.title,
          gray: props.gray,
        })}
      </LayoutTitle>
      <LayoutDescription>{props.description}</LayoutDescription>
    </LayoutHeader>
    <CreditScore equifaxLogo={logo} />
  </Layout>
);

CreditScoreSection.defaultProps = {
  title: "Why you need to monitor your credit score",
  gray: "monitor",
  description: (
    <>
      The score that comes with your <span className="font-500 !text-interface-500">iCash</span> account from <span className="font-500 !text-interface-500">Equifax</span>, the largest credit
      bureau in Canada. It shows how trustworthy you are when it comes to money. Your credit score
      matters when landlords, lenders, and employers determine what to offer you.
    </>
  )
};