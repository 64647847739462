import * as React from "react";
import { ScreenshotContent } from "@brainfinance/icash-component-library";
import { ScreenshotContentSectionProps } from "./screenshot-content";
import phoneScreen from "@images/screen-capture.jpg";

export const ScreenshotContentSection = (props:ScreenshotContentSectionProps) => (
  <ScreenshotContent {...props}>
    <h2 className="global--heading">
      What is a <span className="text-interface-300">credit score</span>?
    </h2>
  </ScreenshotContent>
);

ScreenshotContentSection.defaultProps = {
  description: "Your credit score is a number ranging from 280 to 850 which reflects your creditworthiness to potential lenders. Higher scores make you more likely to qualify for better rates on things like mortgages, credit cards and loans- potentially saving you hundreds and even thousands of dollars.",
  img: phoneScreen
}