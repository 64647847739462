import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { GrayPhrase } from "@components/typography";
import { PageLayout, SEO } from "@components/page-layout";
import { CreditGradeSection } from "@containers/credit-grade";
import { StatisticsList } from "@containers/statistics";
import { CustomerService } from "@containers/customer-service";
import { CreditHistorySection } from "@containers/credit-history";
import { Testimonials } from "@containers/testimonials";
import { BlogSection } from "@containers/blog";
import { FaqPreviews } from "@containers/faq-previews";
import { Box } from "@components/box";
import { ScreenshotContentSection } from "@containers/screenshot-content";
import { CreditScoreSection } from "@containers/credit-score";
import { DataPrivacySection } from "@containers/data-privacy";
import { BlogCard, Button, FaqPreviewAnswer, StepsItem } from "@brainfinance/icash-component-library";
import { SideHeader } from "@containers/header";
import { FullscreenCustom } from "@containers/fullscreen-custom";
import { StepsSection } from "@containers/steps";

// Images
import trsipilotWidget from "@images/trustpilot-widget-img.png";

// Static data
import { FAQCREDIT } from "@containers/faq-previews/faq-previews-data";
import { STEPSCREDIT } from "@containers/steps/steps-data";
import { BLOGPOST } from "@containers/blog/blog-data";

const CreditMonitoring = (props: any) => (
  <PageLayout>
    <SEO title="Credit Monitoring" />
    <SideHeader
      description="Your credit score can have a big impact on your financial future, whether it's buying a house, renting an apartment or landing your dream job. Join us and get the tools you need to help understand, manage and master your credit."
      src={getImage(props.data.file)?.images.fallback?.src || ""}
      srcSet={getImage(props.data.file)?.images.fallback?.srcSet || ""}
      buttonText="Get my credit score monitoring"
      darkBg
    >
      Get your Equifax <br />
      <GrayPhrase>
        credit score
        <br />
        monitoring
      </GrayPhrase>
    </SideHeader>
    <Box className="h-4" />
    <ScreenshotContentSection />
    <Box className="h-[1px]" />
    <CreditScoreSection />
    <Box className="h-[10rem] sm:h-[2rem]" />
    <CreditHistorySection />
    <Box className="h-[3rem] sm:h-[1rem]" />
    <StatisticsList />
    <FullscreenCustom />
    <Box display="flex" className="justify-center py-[8rem] md:py-[5rem]">
      <img
        src={trsipilotWidget}
        alt="Trustpilot Widget"
        className="w-full max-w-[1408px]"
      />
    </Box>
    <FaqPreviews
      title="Commonly asked questions about credit scores"
      gray="questions"
    >
      {FAQCREDIT.map((item, ndx) => (
        <FaqPreviewAnswer key={ndx} question={item.question}>
          {item.answer}
        </FaqPreviewAnswer>
      ))}
    </FaqPreviews>
    <CreditGradeSection />
    <DataPrivacySection />
    <Box className="h-[1px]" />
    <StepsSection
      title="How we can help to improve your credit score"
      gray="improve your credit score"
    >
      {STEPSCREDIT.map((item, ndx) => (
        <StepsItem key={ndx} {...item} />
      ))}
    </StepsSection>
    <Box className="text-center mb-[5.5rem] sm:mb-[2.75rem]">
      <Button className="mx-auto" appearance="tertiary" onClick={() => {}}>
        Start my personal loan
      </Button>
    </Box>
    <BlogSection>
      {BLOGPOST.map((post, ndx) => (
        <BlogCard key={ndx} {...post} />
      ))}
    </BlogSection>
    <CustomerService />
    <Testimonials />
  </PageLayout>
);

export default CreditMonitoring;

export const pageQuery = graphql`
  query {
    file(relativePath: {eq: "credit-monitoring-bg.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1160)
      }
    }
  }
`