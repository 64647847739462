import * as React from "react";
import { Box } from "@components/box";
import { Fullscreen, FullscreenLeft, FullscreenRight } from "@brainfinance/icash-component-library";
import { FullscreenCustomProps } from "./fullscreen-custom";
// Images
import backgroundImg from "@images/bg-custom-fullscreen.png";
import floatingMsg from "@images/message-bubble.png";
import logo from "@images/icash-symbol-black-sized.svg";

export const FullscreenCustom = (props: React.PropsWithChildren<FullscreenCustomProps>) => (
  <Fullscreen>
    <FullscreenLeft logo={logo} style={{ backgroundImage: `url(${props.src})` }} />
    <Box className="absolute items-stretch w-2/3 sm:w-full h-full z-[2]">
      <img src={floatingMsg} className="absolute w-[484px] right-[3.75rem] top-[50%] sm:scale-75" />
    </Box>
    <FullscreenRight buttonText={props.buttonText} grayBg>
      {props.children}
    </FullscreenRight>
  </Fullscreen>
);

FullscreenCustom.defaultProps = {
  title: "Who looks at your credit score and report?",
  src: backgroundImg,
  children: (
    <>
      <h3 className="global--subheading mb-[2rem] sm:mb-[22px]">
        Borrow and save.
        <br />
        Earn up to 20% in cashback!
      </h3>
      <p className="global--paragraph text-interface-300">
        Your credit report is like your financial report card. In addition to banks and lenders, your report can be
        looked at by car dealerships, insurance, cell phone companies, landlords and future employers to judge your
        ability to manage debt and financial obligations.
      </p>
    </>
  ),
  buttonText: "Get my credit score monitoring"
};
