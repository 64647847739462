import * as React from "react";
import { CreditHistory } from "@brainfinance/icash-component-library";
import { CreditHistorySectionProps } from "./credit-history";
import { generateTitle } from "@helpers/generateTitle";
//images
import creditHistoryImg from "@images/credit-history-desktop.png";
import mdCreditHistoryImg from "@images/credit-history-tablet.png";
import smCreditHistoryImg from "@images/credit-history-mobile.png";

export const CreditHistorySection = (props:CreditHistorySectionProps) => (
  <CreditHistory {...props}>
    {generateTitle({
      title: props.title,
      gray: props.gray,
    })}
  </CreditHistory>
);

CreditHistorySection.defaultProps = {
  title: "Get on top of your financial life",
  gray: "financial life",
  imgList: {
    lgImg: creditHistoryImg,
    mdImg: mdCreditHistoryImg,
    smImg: smCreditHistoryImg
  },
  itemList: [
    "Learn how to improve your score",
    "Track your score monthly and keep a close watch",
    "Rule your score and achieve your financial goals"
  ],
  btnText: "Get my credit score monitoring"
}