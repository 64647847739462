import defaultImg from "@images/fullscreen-background.jpg";

export const BLOGPOST = [
  {
    title: "A simple guide to refinancing",
    children:
      "Sed ac feugiat lectus, a maximus ante. Donec imperdiet risus id dolor eleifend, a auctor ipsum fringilla. Fusce mollis facilisis ligula, non porta odio tristique ut.",
    style: {
      backgroundImage: `url(${defaultImg})`,
      backgroundPosition: "center"
    }
  },
  {
    title: "How to improve your credit score in Canada",
    children:
      "Cras eleifend metus turpis, sit amet dignissim ipsum aliquet ut. Integer tincidunt mauris eget ligula imperdiet elementum. Duis mollis eget felis id condimentum. Nam fermentum massa.",
    style: {
      backgroundImage: `url(${defaultImg})`,
      backgroundPosition: "center"
    }
  },
  {
    title: "How late payments impact your credit score",
    children:
      "Pellentesque rutrum vehicula placerat. Pellentesque sodales odio quam, in congue nibh mattis sed. Nam in dolor varius, sollicitudin libero non, vehicula enim.",
    style: {
      backgroundImage: `url(${defaultImg})`,
      backgroundPosition: "center"
    }
  }
]